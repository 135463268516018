<template>
  <div id="section2">
    <div class="fondoGrisClaro" >
      <b-container fluid>
        <b-container >
          <br />
          <h1  class="text-left">
            {{$t("title")}}
          </h1>
          <h3  class="text-left">
            {{$t("title1")}}
          </h3>
          <br />
          <p class="text-justify">
            {{$t("txt")}}
          </p>
          <br>
          <div class="pb-5 mb-2" >
            <a :href="url" class="btn buttonAzul">
              <span>
                {{$t("btn")}}
              </span>
              <span>
                <font-awesome-icon :icon="btnIco" />
              </span>
            </a>
          </div>
        </b-container>
      </b-container>
    </div>
  </div>
</template>

<i18n>
{
  "en":{
    
  },
  "es":{
    "title": "Aumenta la confianza de tus usuarios con UniversalPay."
    , "title1": "Muestra los logotipos y marca de aceptación."
    , "txt": "Comunica a tus clientes que procesen pagos con UniversalPay. De esta manera podrás aumentar su confianza. Hay múltiples opciones a tu disposición: logo UniversalPay, métodos de pago aceptados, botón de pago y solución de pago online Snap*."
    , "btn": "DESCARGAR TODOS "
  }
}
</i18n>

<script>
export default {
  data() {
    return {
      btnIco: ['fas','download']
      , btnUrl: "#"
    };
  },
  computed: {
    btnNames() {
      return this.$t("btnNames");
    }
  }
};
</script>
