<template>
  <div id="section3">
    <div class="fondoWhite" >
      <b-container fluid>
        <b-container>
          <br>
          <b-row>
            <b-col md="4" sm="12" v-for="(item, index) in list" :key="index">
              <b-row>
                <b-col cols="4">
                  <div>
                    <b-img :src="listIcon[index].img"/>
                  </div>
                </b-col>
                <b-col cols="8">
                  <div v-for="(item1, index1) in item.txt" :key="index1" style="font-size: 1.4em">
                    <strong>{{item1}}</strong>
                  </div>
                  <br>
                  <a :href="listIcon[index]" class="btn buttonAzul">
                    <span>
                      {{item.btn}}
                    </span>
                    <span>
                      <font-awesome-icon :icon="btnIco" />
                    </span>
                  </a>
                </b-col>
              </b-row>
              <br>
            </b-col>
          </b-row>
          <br>
        </b-container>
      </b-container>
    </div>
  </div>
</template>

<i18n>
{
  "en":{
    
  },
  "es":{
    "list":[
      {
        "txt": [
          "Logo Pay"
          , "RGBA (png, jpg)"
          , "CMYK (tiff)"
        ]
        , "btn": "DESCARGAR"
      },
      {
        "txt": [
          "Logo Pay"
          , "fondo blanco RGBA"
          , "(png, jpg) CMYK (tiff)"
        ]
        , "btn": "DESCARGAR"
      },
      {
        "txt": [
          "Popular Payments"
          , "Logos Tarjetas"
        ]
        , "btn": "DESCARGAR"
      }
    ]
  }
}
</i18n>

<script>
export default {
  data() {
    return {
      listIcon: [
        {
          img: require("@/assets/images/pay/logo_pay_color.png")
          , url: "#"
        },
        {
          img: require("@/assets/images/pay/logo_pay_negro.png")
          , url: "#"
        },
        {
          img: require("@/assets/images/pay/logo_tarjeta.png")
          , url: "#"
        }
      ],
      btnIco: ['fas','download']
    }
  },
  methods: {
    
  },
  computed: {
    list() {
      return this.$t("list");
    }
  }
};
</script>
