<template>
  <div id="section1">
    <div class="fondoFwhite" :style="'background-image: url(' + payImg + ');'">  
      <b-container fluid>
        <b-container>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </b-container>
      </b-container>
    </div>      
  </div>
</template>


<script>
export default {
  data() {
    return {
      payImg: require("@/assets/images/pay/img_header_partens.jpg")
    }
  }
};
</script>
