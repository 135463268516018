<template>
  <div class="partners5">
    <div class="wrapper">
      <Section1 />
      <Section2 />
      <Section3 />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Section1 from "@/components/pay/partner/page5/Section1";
import Section2 from "@/components/pay/partner/page5/Section2";
import Section3 from "@/components/pay/partner/page5/Section3";

export default {
  name: "partners5",
  components: {
    Section1,
    Section2,
    Section3,
  },
  head: {
    title: {
      inner: "Central de logotipos"
    },
  },
  mounted() {
    //this.$i18n.locale = "es";
  }
};
</script>